import React from "react";
import { Box, Flex, Stack, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import GButton from "components/ui/button/Button";
export default function ViewVacationTab({ details, onClose }) {
  const { t } = useTranslation();

  return (
    <>
      <Stack spacing={2} py={10}>
        <Box
          bg={"gray.200"}
          border={"1px"}
          borderColor={"gray.300"}
          padding={2}
          borderRadius={5}
          textColor="gray.600"
          // fontWeight={"semibold"}
          display={"flex"}
          justifyContent={"space-between"}
        >
          <Text>{t("pages.hr.avilabel_hours")}</Text>

          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            padding={2}
            borderRadius={"full"}
            width={"35px"}
            height={"35px"}
            bg={"blue.500"}
            color={"white"}
          >
            {details?.Available}
          </Box>
        </Box>
      </Stack>

      <Flex gap={4}>
        <GButton
          title={t("general.close")}
          onClick={onClose}
          bg="red.600"
          border="1px solid transparent"
          color="light"
          hover={{
            color: "red.600",
            borderColor: "red.600",
            bg: "none",
          }}
        />
      </Flex>
    </>
  );
}
