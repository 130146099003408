import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Box, Flex, useToast, Button } from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";

import ViewVacationTab from "./ViewVacationTab";
import FormTab from "./FormTab";

export default function AddPermissionTypeModal({ onClose, UserId }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });

  const { permissionVactionChangeById } = useSelector(
    (state) => state.HrSelfServices
  );
  const [showViewVaction, setShowViewVaction] = useState(false);

  return (
    <>
      <GModal
        onClose={onClose}
        modalHeader={t("pages.hr.self_service_object.add_new_permission_type")}
        modalContent={
          <Box p={4}>
            <Flex display={"flex"} alignItems={"center"} gap={2} mb={"10px"}>
              <Button
                colorScheme="telegram"
                size="sm"
                width={"100%"}
                height={"40px"}
                onClick={() => setShowViewVaction(false)}
              >
                <Flex justifyContent={"space-between"} alignItems={"center"}>
                  <span> {t("pages.hr.add_new")} </span>
                </Flex>
              </Button>

              <Button
                colorScheme="green"
                size="sm"
                width={"100%"}
                height={"40px"}
                onClick={() => setShowViewVaction(true)}
              >
                <Flex justifyContent={"space-between"} alignItems={"center"}>
                  <span> {t("pages.hr.view_vacation")} </span>
                </Flex>
              </Button>
            </Flex>

            {showViewVaction ? (
              <>
                <ViewVacationTab
                  details={permissionVactionChangeById[0]}
                  onClose={onClose}
                />
              </>
            ) : (
              <FormTab UserId={UserId} onClose={onClose} />
            )}
          </Box>
        }
      ></GModal>
    </>
  );
}
