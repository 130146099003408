import React, { useState } from "react";
import { MdPictureAsPdf } from "react-icons/md";

import { Text, Box, Heading, Button, useStatStyles } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import GModal from "components/ui/modal/Modal";
import PDFModal from "components/ui/modal/PDFModal";

export default function ExportLetterPdf({ onClose }) {
  const { t } = useTranslation();
  const [showPrintPaper, setShowPrintPaper] = useState(false);

  return (
    <div>
      <GModal
        onClose={onClose}
        modalHeader={t("general.export")}
        modalContent={
          <Box p={6}>
            <Heading
              as="h4"
              fontSize="1.3rem"
              mb={6}
              textTransform="capitalize"
            >
              {t("general.exportPdf")}
            </Heading>
            <Button
              // p={5}
              gap={2}
              type="button"
              bg="primary"
              color="light"
              textTransform="capitalize"
              fontSize="1rem"
              onClick={() => setShowPrintPaper(true)}
            >
              <Box textColor={"white"}>
                <MdPictureAsPdf size={30} />
              </Box>
              <Text textTransform={"capitalize"}>{t("general.export")}</Text>
            </Button>
          </Box>
        }
      ></GModal>

      {showPrintPaper && <PDFModal onClose={() => setShowPrintPaper(false)} />}
    </div>
  );
}
