import { Box, Button, Text } from "@chakra-ui/react";
import React from "react";
import GModal from "./Modal";
import { useTranslation } from "react-i18next";
import { IoMdCloudDownload } from "react-icons/io";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import logo from "./../../../assets/images/logo.png";

export default function PDFModal({ onClose }) {
  const { t } = useTranslation();

  return (
    <>
      <GModal
        onClose={onClose}
        size="xl"
        modalHeader={t("general.download")}
        modalContent={
          <Box p={6}>
            <LetterTemplate />
            <Button
              p={5}
              gap={2}
              mt={5}
              type="button"
              bg="primary"
              color="light"
              textTransform="capitalize"
              fontSize="1rem"
              onClick={() => generatePDF()}
            >
              <Box textColor={"white"}>
                <IoMdCloudDownload size={30} />
              </Box>
              <Text textTransform={"capitalize"}>{t("general.download")}</Text>
            </Button>
          </Box>
        }
      ></GModal>
    </>
  );
}

function LetterTemplate() {
  const styles = {
    container: {
      maxWidth: "600px",
      margin: "0 auto",
      padding: "20px 20px 50px 20px",
      //   border: "1px solid #ccc",
      fontFamily: "Arial, sans-serif",
    },
    logo: {
      width: "100px",
    },
    date: {
      textAlign: "right",
      marginTop: "20px",
    },
    to: {
      marginTop: "40px",
    },
    body: {
      marginTop: "20px",
      lineHeight: "1.6",
    },
    signature: {
      marginTop: "40px",
    },
  };

  return (
    <div id="letter-content" style={styles.container}>
      <img src={logo} alt="Logo" style={styles.logo} />
      <div style={styles.date}>Wednesday, July 17, 2024</div>
      <div style={styles.to}>To: test sasdasd asdsadsa</div>
      <div style={styles.body}>
        <p>Dear Sir/Madam,</p>
        <p>
          This is to confirm that
          <strong>Ms. Yasmine Amr Mostafa El Shishiny</strong> has been employed
          on a full-time basis at New StartUp
          <strong>'Ristecho'</strong>, since
          <strong>Monday, November 25, 2019</strong> till the current date,
          employee’s current position is <strong>HR Director</strong>.
        </p>
        <p>
          with Current Net Monthly Salary: <strong>EGP 0</strong>.
        </p>
        <p>And social insurance number: -</p>
        <p>
          This contract is valid until <strong>31st of August 2024</strong>, the
          contract is subject to annual renewal.
        </p>
        <p>
          This letter was issued upon the employee’s request without any
          liability to the school.
        </p>
      </div>
      <div style={styles.signature}>
        <p>Sincere Regards,</p>
        <p>muhammad Abozaid</p>
        <p>Director - Human Resources & Administration</p>
      </div>
    </div>
  );
}

export async function generatePDF() {
  const input = document.getElementById("letter-content");
  const canvas = await html2canvas(input);
  const imgData = canvas.toDataURL("image/png");

  const pdf = new jsPDF();
  const imgProperties = pdf.getImageProperties(imgData);
  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

  pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
  pdf.save("title.pdf");
}
