import { useEffect, useState } from "react";
import CryptoJS from "crypto-js";

const useDecryptedPrivilege = () => {
  const [retrievedArray, setRetrievedArray] = useState([]);

  useEffect(() => {
    const storedPrivilege = localStorage.getItem("privilege");

    if (storedPrivilege) {
      const decryptedString = CryptoJS.AES.decrypt(
        storedPrivilege,
        "yourSecretKey"
      ).toString(CryptoJS.enc.Utf8);
      const retrievedArray = JSON.parse(decryptedString);
      setRetrievedArray(retrievedArray);
    } else {
      setRetrievedArray([]);
    }
  }, []);

  return retrievedArray;
};

export default useDecryptedPrivilege;
