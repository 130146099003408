import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Flex, Spinner, Text } from "@chakra-ui/react";
import GModal from "components/ui/modal/Modal";
import GButton from "components/ui/button/Button";
import { useDispatch, useSelector } from "react-redux";
import { viewLeavePermission } from "features/hr/selfServices/services/HrSelfServices";
import HrLetterDetails from "./HrLetterDetails";
import HrProcessInfo from "./HrProcessInfo";

export default function ViewLeavePermission({ onClose, id }) {
  const UserId = localStorage.getItem("userId");

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { detailsLeavePermission } = useSelector(
    (state) => state.HrSelfServices
  );
  const [showContent, setShowContent] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    const values = {
      PermissionRequestId: id,
      UserId: UserId,
    };
    dispatch(viewLeavePermission(values));
    setTimeout(() => {
      setShowContent(true);
    }, 2000);
  }, [dispatch, id, UserId]);

  return (
    <>
      <GModal
        onClose={onClose}
        modalHeader={t("pages.hr.view_leave_permissmion")}
        modalContent={
          showContent ? (
            <Box p={4}>
              <Flex display={"flex"} alignItems={"center"} gap={2} mb={"10px"}>
                <Button
                  colorScheme="telegram"
                  size="sm"
                  width={"100%"}
                  height={"40px"}
                  onClick={() => setShowDetails(false)}
                >
                  <Flex justifyContent={"space-between"} alignItems={"center"}>
                    <span> {t("pages.hr.permission_request_details")} </span>
                  </Flex>
                </Button>

                <Button
                  colorScheme="green"
                  size="sm"
                  width={"100%"}
                  height={"40px"}
                  onClick={() => setShowDetails(true)}
                >
                  <Flex justifyContent={"space-between"} alignItems={"center"}>
                    <span> {t("pages.hr.process_info")} </span>
                  </Flex>
                </Button>
              </Flex>

              <Flex display={"flex"} alignItems={"center"} gap={2} mb={"10px"}>
                <Button
                  colorScheme="gray"
                  size="sm"
                  width={"100%"}
                  height={"40px"}
                  //   onClick={() => setShowLetterPrint(true)}
                >
                  <Flex justifyContent={"space-between"} alignItems={"center"}>
                    <span> {t("pages.hr.PrintFile")} </span>
                  </Flex>
                </Button>
              </Flex>

              <Box>
                {showDetails ? (
                  <HrProcessInfo details={detailsLeavePermission[0]} />
                ) : (
                  <HrLetterDetails details={detailsLeavePermission[0]} />
                )}
              </Box>
              <Flex gap={4} marginTop={5}>
                <GButton
                  title={t("general.close")}
                  onClick={onClose}
                  bg="red.600"
                  border="1px solid transparent"
                  color="light"
                  hover={{
                    color: "red.600",
                    borderColor: "red.600",
                    bg: "none",
                  }}
                />
              </Flex>
            </Box>
          ) : (
            <Flex
              bg="light"
              p={4}
              gap={2}
              justifyContent="center"
              flexDir={"column"}
              alignItems={"center"}
            >
              <Spinner />
              <Text fontWeight={"bold"}> {t("general.loading")} </Text>
            </Flex>
          )
        }
      ></GModal>
    </>
  );
}
