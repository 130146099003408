import { Box, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

export default function HrLetterDetails({ details }) {
  const { t } = useTranslation();
  // console.log(details);
  return (
    <Stack spacing={6}>
      <Box>
        <Text>{t("pages.hr.Employee_name")}</Text>
        <Box
          border={"1px"}
          borderColor={"gray.300"}
          padding={2}
          borderRadius={5}
          textColor="gray.600"
          // fontWeight={"semibold"}
          marginTop={"2"}
        >
          <Text>{details?.FullEnName}</Text>
        </Box>
      </Box>
      <Box>
        <Text>{t("pages.hr.date")}</Text>
        <Box
          border={"1px"}
          borderColor={"gray.300"}
          padding={2}
          borderRadius={5}
          textColor="gray.600"
          // fontWeight={"semibold"}
          marginTop={"2"}
        >
          <Text>{details?.CreatedDate}</Text>
        </Box>
      </Box>
      <Box>
        <Text>{t("pages.hr.from")}</Text>
        <Box
          border={"1px"}
          borderColor={"gray.300"}
          padding={2}
          borderRadius={5}
          textColor="gray.600"
          // fontWeight={"semibold"}
          marginTop={"2"}
        >
          <Text>{details?.FromDate}</Text>
        </Box>
      </Box>
      <Box>
        <Text>{t("pages.hr.to")}</Text>
        <Box
          border={"1px"}
          borderColor={"gray.300"}
          padding={2}
          borderRadius={5}
          textColor="gray.600"
          // fontWeight={"semibold"}
          marginTop={"2"}
        >
          <Text>{details?.ToDate}</Text>
        </Box>
      </Box>
      <Box>
        <Text>{t("pages.hr.FromTime")}</Text>
        <Box
          border={"1px"}
          borderColor={"gray.300"}
          padding={2}
          borderRadius={5}
          textColor="gray.600"
          // fontWeight={"semibold"}
          marginTop={"2"}
        >
          <Text>{details?.FromTime}</Text>
        </Box>
      </Box>
      <Box>
        <Text>{t("pages.hr.ToTime")}</Text>
        <Box
          border={"1px"}
          borderColor={"gray.300"}
          padding={2}
          borderRadius={5}
          textColor="gray.600"
          // fontWeight={"semibold"}
          marginTop={"2"}
        >
          <Text>{details?.ToTime}</Text>
        </Box>
      </Box>

      <Box>
        <Text>{t("pages.hr.comments")}</Text>
        <Box
          border={"1px"}
          borderColor={"gray.300"}
          padding={2}
          borderRadius={5}
          textColor="gray.600"
          // fontWeight={"semibold"}
          marginTop={"2"}
        >
          <Text>{details?.PermissionCauses || "No Comment"}</Text>
        </Box>
      </Box>
    </Stack>
  );
}
