import {
  Box,
  Flex,
  Input,
  Stack,
  Text,
  useToast,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Textarea,
  Spinner,
} from "@chakra-ui/react";
import { BiSelectMultiple } from "react-icons/bi";
import GButton from "components/ui/button/Button";
import MultiSelect from "components/ui/multiSelect/MultiSelect";
import {
  addNewLeavePermission,
  getLeavePermission,
  getPermissionVactionChangeById,
} from "features/hr/selfServices/services/HrSelfServices";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

export default function FormTab({ onClose, UserId }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    trigger,
    getValues,
  } = useForm();
  const toast = useToast({
    position: "top",
    duration: 3000,
    status: "success",
  });
  const toast2 = useToast({ position: "top", duration: 3000, status: "error" });

  /* -------------------------------------------------------------------------- */
  const {
    employeeDropdown,
    isLoadingPremissionType,
    leavePermissionTypeDropDown,
  } = useSelector((state) => state.HrSelfServices);
  /* -------------------------------------------------------------------------- */
  const [employeeIdSelected, setemployeeIdSelected] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [employeePermissionIdSelected, setEmployeePermissionIdSelected] =
    useState(null);
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    const ViewData = {
      EmployeeId: employeeIdSelected,
      PermissionTypeId: employeePermissionIdSelected || 1,
      FromDate: fromDate,
      // ToDate: toDate,
    };
    dispatch(getPermissionVactionChangeById(ViewData));
  }, [dispatch, employeeIdSelected, employeePermissionIdSelected]);

  /* -------------------------------------------------------------------------- */
  const isOnlySpaces = (value) => {
    // Check if the value contains only spaces
    return !value.trim();
  };

  const handleInputChange = async (e, type) => {
    setValue(type, e.target.value);
    await trigger(type);
  };
  /* -------------------------------------------------------------------------- */

  return (
    <>
      <form
        onSubmit={handleSubmit((values) => {
          values.UserId = UserId;

          if (
            values?.EmployeeId === undefined ||
            values?.PermissionTypeId === undefined
          ) {
            toast2({
              description: t("validation.choose_first"),
            });
          } else {
            dispatch(addNewLeavePermission(values))
              .unwrap()
              .then((res) => {
                toast({
                  description: t("pages.hr.leave_permission_Added"),
                });
                onClose();
                dispatch(getLeavePermission(UserId));
              });
          }
        })}
      >
        <Stack spacing={6}>
          <Box>
            <MultiSelect
              title={t("pages.hr.self_service_object.select_employee")}
              isMulti={false}
              // value={getValues()?.EmployeeId}
              data={
                employeeDropdown &&
                employeeDropdown?.map((el) => ({
                  ...el,
                  label: el.EmployeeName,
                  value: el.EmployeeId,
                }))
              }
              onChange={(e) => {
                setValue("EmployeeId", e?.EmployeeId);
                setEmployeePermissionIdSelected(null);
                setemployeeIdSelected(e?.EmployeeId);
              }}
            />
          </Box>
          {isLoadingPremissionType ? (
            <Flex
              bg="light"
              p={4}
              gap={2}
              justifyContent="center"
              flexDir={"column"}
              alignItems={"center"}
            >
              <Spinner />
              <Text fontWeight={"bold"}>{t("general.loading")}</Text>
            </Flex>
          ) : (
            <>
              {employeeIdSelected ? (
                <>
                  <Box>
                    <MultiSelect
                      title={t("pages.hr.self_service_object.permission_type")}
                      isMulti={false}
                      value={getValues()?.PermissionTypeId}
                      data={
                        leavePermissionTypeDropDown &&
                        leavePermissionTypeDropDown?.map((el) => ({
                          ...el,
                          label: el.PermissionTypeNameEn,
                          value: el.PermissionTypeId,
                        }))
                      }
                      onChange={(e) => {
                        setValue("PermissionTypeId", e?.PermissionTypeId);
                        setEmployeePermissionIdSelected(e?.PermissionTypeId);
                      }}
                    />
                  </Box>

                  <FormControl isInvalid={errors.FromDate}>
                    <FormLabel
                      htmlFor="FromDate"
                      style={{ textTransform: "capitalize" }}
                    >
                      {t("pages.hr.extraction_start_date")}
                    </FormLabel>
                    <Controller
                      name="FromDate"
                      control={control}
                      // defaultValue={`${year}-${month}-${day}`}
                      rules={{
                        required: t("validation.required"),
                        validate: (value) => {
                          const intValue = parseInt(value, 10);
                          return (
                            intValue >= 0 ||
                            t("validation.must_be_non_negative")
                          );
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder={t("pages.hr.extraction_start_date")}
                          onChange={(e) => {
                            handleInputChange(e, "FromDate");
                            setFromDate(e?.target?.value);
                          }}
                          type="date"
                          value={getValues()?.FromDate}
                        />
                      )}
                    />
                    <FormErrorMessage>
                      {errors.FromDate && errors.FromDate.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.ToDate}>
                    <FormLabel
                      htmlFor="ToDate"
                      style={{ textTransform: "capitalize" }}
                    >
                      {t("pages.hr.extraction_end_date")}
                    </FormLabel>
                    <Controller
                      name="ToDate"
                      control={control}
                      // defaultValue={`${year2}-${month2}-${day2}`}
                      rules={{
                        required: t("validation.required"),
                        validate: (value) => {
                          const intValue = parseInt(value, 10);
                          return (
                            intValue >= 0 ||
                            t("validation.must_be_non_negative")
                          );
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder={t("pages.hr.extraction_end_date")}
                          onChange={(e) => {
                            handleInputChange(e, "ToDate");
                            setToDate(e?.target?.value);
                          }}
                          type="date"
                          value={getValues()?.ToDate}
                        />
                      )}
                    />
                    <FormErrorMessage>
                      {errors.ToDate && errors.ToDate.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.FromTime}>
                    <FormLabel
                      htmlFor="FromTime"
                      style={{ textTransform: "capitalize" }}
                    >
                      {t("pages.hr.FromTime")}
                    </FormLabel>
                    <Controller
                      name="FromTime"
                      control={control}
                      // defaultValue={`${year}-${month}-${day}`}
                      rules={{
                        required: t("validation.required"),
                        validate: (value) => {
                          const intValue = parseInt(value, 10);
                          return (
                            intValue >= 0 ||
                            t("validation.must_be_non_negative")
                          );
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder={t("pages.hr.FromTime")}
                          onChange={(e) => {
                            handleInputChange(e, "FromTime");
                          }}
                          type="time"
                        />
                      )}
                    />
                    <FormErrorMessage>
                      {errors.FromTime && errors.FromTime.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.ToTime}>
                    <FormLabel
                      htmlFor="ToTime"
                      style={{ textTransform: "capitalize" }}
                    >
                      {t("pages.hr.ToTime")}
                    </FormLabel>
                    <Controller
                      name="ToTime"
                      control={control}
                      // defaultValue={`${year2}-${month2}-${day2}`}
                      rules={{
                        required: t("validation.required"),
                        validate: (value) => {
                          const intValue = parseInt(value, 10);
                          return (
                            intValue >= 0 ||
                            t("validation.must_be_non_negative")
                          );
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder={t("pages.hr.ToTime")}
                          onChange={(e) => {
                            handleInputChange(e, "ToTime");
                          }}
                          type="time"
                        />
                      )}
                    />
                    <FormErrorMessage>
                      {errors.ToTime && errors.ToTime.message}
                    </FormErrorMessage>
                  </FormControl>

                  <Box>
                    <Text>{t("pages.hr.self_service_object.comments")}</Text>
                    <Textarea
                      placeholder="Here is a sample placeholder"
                      marginTop={"2"}
                      value={getValues()?.PermissionNotes}
                      onChange={(e) =>
                        setValue("PermissionNotes", e.target.value)
                      }
                    />
                  </Box>
                </>
              ) : (
                <>
                  <Flex display={"flex"} alignItems={"center"} gap={2}>
                    <Box color={"blue.500"}>
                      <BiSelectMultiple size={25} />
                    </Box>
                    <Text color={"blue.500"} fontSize={"14px"}>
                      {t("pages.hr.slectEmployee")}
                    </Text>
                  </Flex>
                </>
              )}
            </>
          )}

          <Flex gap={4}>
            <GButton
              type="submit"
              title={t("general.save")}
              bg="primary"
              color="light"
              border="1px solid transparent"
              hover={{
                color: "primary",
                borderColor: "primary",
                bg: "none",
              }}
            />
            <GButton
              title={t("general.close")}
              onClick={onClose}
              bg="red.600"
              border="1px solid transparent"
              color="light"
              hover={{
                color: "red.600",
                borderColor: "red.600",
                bg: "none",
              }}
            />
          </Flex>
        </Stack>
      </form>
    </>
  );
}
