import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import Pagination from "components/ui/pagination/Pagination";
import ViewBankLetter from "./modal/ViewBankLetter";
//

const BankLetterTable = ({ data }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  /* --------------------------------------------- Start Pagination  -----------------------------------------------*/
  const recordsPerPage = 10;
  const [currentPageActive, setCurrentPageActive] = useState(1);
  const prePage = (currentPage, setCurrentPage) => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = (currentPage, setCurrentPage, npage) => {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const calculateRecords = (data, currentPage, recordsPerPage) => {
    const firstIndex = (currentPage - 1) * recordsPerPage;
    const lastIndex = Math.min(currentPage * recordsPerPage, data?.length);
    return data?.slice(firstIndex, lastIndex);
  };

  // Calculate records for activeDiscounts
  const bankLetterData = calculateRecords(
    data,
    currentPageActive,
    recordsPerPage
  );
  /* --------------------------------------------- End Pagination  -----------------------------------------------*/

  return (
    <Box
      bg="light"
      overflow="auto"
      borderRadius={4}
      boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
    >
      <Table>
        <Thead>
          <Tr>
            <Th>{t("pages.hr.selfservices.TicketNo")}</Th>
            <Th>{t("pages.hr.selfservices.ProcessName")}</Th>
            <Th>{t("pages.hr.selfservices.Requester")}</Th>
            <Th>{t("pages.hr.selfservices.Employee")}</Th>
            <Th>{t("pages.hr.selfservices.Date")}</Th>
            <Th>{t("pages.hr.selfservices.RequestStatus")}</Th>
            <Th>{t("general.view")}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {bankLetterData?.map((el, index) => (
            <Tr key={index}>
              <Td>{el?.HrLetterRequestId}</Td>
              <Td>{el?.ProcessName}</Td>
              <Td>{el?.HrLetterTo}</Td>
              <Td>{el?.FullEnName}</Td>
              <Td>{el?.CreatedDate}</Td>
              <Td>{el?.Status}</Td>
              <Td>
                <Button
                  colorScheme="green"
                  size="sm"
                  onClick={() => setShowModal(el?.HrLetterRequestId)}
                >
                  <Flex justifyContent={"space-between"} alignItems={"center"}>
                    <span> {t("pages.hr.view")} </span>
                  </Flex>
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Pagination
        currentPage={currentPageActive}
        setCurrentPage={setCurrentPageActive}
        npage={Math.ceil(data?.length / recordsPerPage)}
        prePage={() => prePage(currentPageActive, setCurrentPageActive)}
        nextPage={() =>
          nextPage(
            currentPageActive,
            setCurrentPageActive,
            Math.ceil(data?.length / recordsPerPage)
          )
        }
      />

      {showModal && (
        <>
          <ViewBankLetter id={showModal} onClose={() => setShowModal(false)} />
        </>
      )}
    </Box>
  );
};

export default BankLetterTable;
