import React, { useEffect, useState } from "react";
import { Box, Button, Flex, Spinner, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import LeaveRequestsTable from "./components/LeaveRequestsTable";
import AddLeaveRequestModal from "./components/modal/AddLeaveRequestModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getEmployeeDropDown,
} from "../services/HrSelfServices";
import NoDataFound from "components/ui/noDataFound/NoDataFound";
import ErrorHandelText from "components/ui/errorHandel/ErrorHandelText";

export default function LeaveRequests() {
  const UserId = localStorage.getItem("userId");
  const { t } = useTranslation();
  const disPatch = useDispatch();
  const [showNewModal, setShowNewModal] = useState(false);
  const data = useSelector((state) => state.HrSelfServices);
  console.log(data);

  // useEffect(() => {
  //   disPatch(getEmployeeDropDown(UserId));
  // }, [disPatch, UserId]);

  useEffect(() => {
    if (data?.employeeDropdown?.length === 0) {
      disPatch(getEmployeeDropDown(UserId));
    } else {
      return;
    }
  }, [disPatch, UserId, data?.employeeDropdown]);

  return (
    <Box>
      <Box
        bg="light"
        marginInline={-6}
        marginTop={-6}
        padding={6}
        borderTop="1px solid"
        borderColor="border"
        marginBottom={6}
      >
        <Flex alignItems="center" justifyContent="space-between" gap={4}>
          <Text fontSize="1.2rem" color="dark" textTransform="capitalize">
            {t("pages.hr.selfservices.leave_requests")}
          </Text>

          <Button
            type="button"
            bg="primary"
            color="light"
            textTransform="capitalize"
            fontSize="1rem"
            onClick={() => setShowNewModal(true)}
          >
            <Flex alignItems="center" gap={1}>
              {t("pages.hr.selfservices.new_leave_requests")}
            </Flex>
          </Button>
        </Flex>
      </Box>

      {data?.isLoading ? (
        <Flex bg="light" p={4} justifyContent="center">
          <Spinner />
        </Flex>
      ) : data?.errors ? (
        <Flex bg="light" p={4} justifyContent="center">
          <ErrorHandelText />
        </Flex>
      ) : data?.leaveReportData?.length === 0 ? (
        <NoDataFound />
      ) : (
        <LeaveRequestsTable data={[{}]} />
      )}

      {showNewModal && (
        <AddLeaveRequestModal
          onClose={() => setShowNewModal(false)}
          UserId={UserId}
        />
      )}
    </Box>
  );
}
